import { TreeView, TreeViewExpandChangeEvent, processTreeViewItems } from '@progress/kendo-react-treeview';
import { useCallback, useState } from 'react';
import { StorageItem, StorageKind } from '../../constants/document';
import DocumentsActions from '../documents-manager/DocumentsActions';
import SharedLinkCreator from '../shared-links/SharedLink';
import AddFolder from './AddFolder';
import RenameItem from './RenameItem';
import styles from './TreeViewDocuments.module.css';
import TreeViewItem from './TreeViewItem';
import UploadFile from './UploadFile';

const TreeViewDocuments = ({
  items,
  selectFile,
  selectedFile,
  addNewFolder,
  addNewDocument,
  renameDocument,
  renameFolder,
  deleteDocument,
  deleteFolder,
  deleteContentFolder,
}: any) => {
  const rootFolderName = process.env.REACT_APP_THEME_ROOT_FOLDER ?? 'MotoGP INFORMATION';
  const [expand, setExpand] = useState<any>({ ids: [rootFolderName], idField: 'text' });
  const [newFolderVisible, setNewFolderVisible] = useState<boolean>(false);
  const [newFileVisible, setNewFileVisible] = useState<boolean>(false);
  const [renameItemVisible, setRenameItemVisible] = useState<any>({
    visible: false,
    title: '',
    item: null,
    callBack: null,
  });
  const [sharedLinkVisible, setSharedLinkVisible] = useState<any>({ visible: false, item: null, callBack: null });
  const [selectedPath, setSelectedPath] = useState<string>();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>();

  const onExpandChange = useCallback(
    ({ item }: TreeViewExpandChangeEvent) => {
      let ids = expand.ids.slice();
      const index = ids.indexOf(item.text);

      index === -1 ? ids.push(item.text) : ids.splice(index, 1);
      setExpand({ ids, idField: 'text' });
      if (item.kind === StorageKind.folder) {
        setSelectedPath(item.path);
      }
    },
    [expand],
  );

  const onItemClick = useCallback(
    ({ item, syntheticEvent, itemHierarchicalIndex }: any) => {
      if (
        syntheticEvent.target.className !== 'k-button-icon k-icon k-i-folder-add' &&
        syntheticEvent.target.className !== 'k-button-icon k-icon k-i-file-add'
      ) {
        let ids = expand.ids.slice();
        const index = ids.indexOf(item.text);

        index === -1 ? ids.push(item.text) : ids.splice(index, 1);
        setExpand({ ids, idField: 'text' });
        selectFile(item);
        setSelectedItemIds([itemHierarchicalIndex]);

        if (item.kind === StorageKind.folder) {
          setSelectedPath(item.path);
        }
      }
    },
    [expand, selectFile],
  );

  const onClickAddFolder = useCallback((item: StorageItem) => {
    if (item.path) {
      setSelectedPath(item.path);
    }
    setNewFolderVisible(true);
  }, []);

  const onSubmitAddFolder = useCallback(
    (result: any) => {
      addNewFolder(selectedPath, result.folderName);
      setNewFolderVisible(false);
    },
    [addNewFolder, selectedPath],
  );

  const onClickAddDocument = useCallback((item: StorageItem) => {
    if (item.path) {
      setSelectedPath(item.path);
    }

    setNewFileVisible(true);
  }, []);

  const onSubmitRenameDocument = useCallback(
    (item: StorageItem, newFileName: string) => {
      renameDocument(item, newFileName);
      setRenameItemVisible({ visible: false, title: '', item: null });
    },
    [renameDocument],
  );

  const onSubmitRenameFolder = useCallback(
    (item: StorageItem, newFolderName: string) => {
      renameFolder(item, newFolderName);
      setRenameItemVisible({ visible: false, title: '', item: null });
    },
    [renameFolder],
  );

  const onClickRenameDocument = useCallback(
    (item: StorageItem) => {
      setRenameItemVisible({ visible: true, title: 'Rename file', item, callBack: onSubmitRenameDocument });
    },
    [onSubmitRenameDocument],
  );

  const onClickRenameFolder = useCallback(
    (item: StorageItem) => {
      setRenameItemVisible({ visible: true, title: 'Rename folder', item, callBack: onSubmitRenameFolder });
    },
    [onSubmitRenameFolder],
  );

  const onClickShareDocument = useCallback(async (item: StorageItem) => {
    setSharedLinkVisible({ visible: true, item, callBack: () => setSharedLinkVisible({ visible: false }) });
  }, []);

  const onSubmitAddDocument = useCallback(
    (items: StorageItem[]) => {
      addNewDocument(selectedPath, items);
    },
    [addNewDocument, selectedPath],
  );

  const onClickDeleteDocument = useCallback(
    (item: StorageItem) => {
      deleteDocument(item);
    },
    [deleteDocument],
  );

  const onClickDeleteFolder = useCallback(
    (item: StorageItem) => {
      deleteFolder(item);
    },
    [deleteFolder],
  );

  const onClickDeleteContentFolder = useCallback(
    (item: StorageItem) => {
      deleteContentFolder(item);
    },
    [deleteContentFolder],
  );

  return (
    <>
      <DocumentsActions
        item={selectedFile}
        onClickAddFolder={onClickAddFolder}
        onClickAddDocument={onClickAddDocument}
        onClickRenameDocument={onClickRenameDocument}
        onClickRenameFolder={onClickRenameFolder}
        onClickDeleteDocument={onClickDeleteDocument}
        onClickDeleteFolder={onClickDeleteFolder}
        onClickShareDocument={onClickShareDocument}
        onClickDeleteContentFolder={onClickDeleteContentFolder}
      />
      <TreeView
        data={processTreeViewItems(items, {
          expand: expand,
          select: selectedItemIds,
        })}
        className={styles['tree-view']}
        expandIcons={true}
        onExpandChange={onExpandChange}
        onItemClick={onItemClick}
        aria-multiselectable={false}
        checkboxes={false}
        item={(props: any) => <TreeViewItem {...props} />}
      />
      {newFolderVisible === true ? (
        <AddFolder onSubmit={(result: any) => onSubmitAddFolder(result)} onCancel={() => setNewFolderVisible(false)} />
      ) : (
        <></>
      )}
      {renameItemVisible.visible === true ? (
        <RenameItem
          onSubmit={(result: any) => renameItemVisible.callBack(renameItemVisible.item, result.name)}
          onCancel={() => setRenameItemVisible({ visible: false, title: '', item: null })}
          title={renameItemVisible.title}
          currentName={renameItemVisible.item.text}
        />
      ) : (
        <></>
      )}
      {newFileVisible === true ? (
        <UploadFile
          onSubmit={(result: any) => onSubmitAddDocument(result)}
          onCancel={() => setNewFileVisible(false)}
          onFinishUpload={() => {
            setNewFileVisible(false);
          }}
          folderName={selectedPath}
          test={'dummy'}
        />
      ) : (
        <></>
      )}
      {sharedLinkVisible.visible && (
        <SharedLinkCreator
          data-testid="confirmation-dialog"
          onClose={sharedLinkVisible.callBack}
          item={sharedLinkVisible.item}
        />
      )}
    </>
  );
};

export default TreeViewDocuments;
