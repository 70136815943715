import { Button } from '@progress/kendo-react-buttons';
import { DateRangePicker, SelectionRange } from '@progress/kendo-react-dateinputs';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../actions/api';
import { showNotification } from '../../actions/notifications';
import { SharedLink, StorageItem, StorageKind } from '../../constants/document';
import NotificationsTypes from '../../constants/notification';
import documentsService from '../../services/documentsService';
import './SharedLink.css';

interface ISharedLinkProps {
  item: StorageItem;
  onClose?(): void;
}

const SharedLinkCreator = ({ item, onClose }: ISharedLinkProps): React.ReactElement => {
  const dispatch = useDispatch();
  const [sharedLink, setSharedLink] = useState<SharedLink>();
  const [valueDate, setValueDate] = useState<SelectionRange>();

  useEffect(() => {
    setValueDate({
      start: formatGetOnlyDate(new Date()),
      end: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createSharedLink = async (item: StorageItem) => {
    try {
      dispatch(showLoading());
      var result = await documentsService.createSharedLink(
        item.path,
        formatGetOnlyDate(valueDate?.start ?? new Date()),
        formatGetOnlyDate(valueDate?.end ?? new Date()),
      );
      setSharedLink(result);
      copySharedLinkToClipboard(url);
    } catch (error: any) {
      dispatch(
        showNotification({
          message: 'Create shared link failed, try again later or contact the support team',
          type: NotificationsTypes.Error,
        }),
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const formatGetOnlyDate = (value: Date) =>
    new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0, 0);

  const copySharedLinkToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };

  const url = `${window.location.origin}/shared/${item.kind === StorageKind.document ? 'file' : 'folder'}/${
    sharedLink?.codeLink
  }`;
  
  return (
    <Dialog title={'Shared link'} onClose={onClose}>
      <div>
        <p>Select the dates where the link is valid.</p>
        <DateRangePicker
          min={formatGetOnlyDate(new Date())}
          value={valueDate}
          onChange={(event: any) => setValueDate(event.value)}
          format="dd/MMMM/yyyy"
        />
      </div>
      <Button className="btn-action-add" onClick={() => createSharedLink(item)}>
        Get Link
      </Button>
      {sharedLink && sharedLink.codeLink ? (
        <p>
          {url}
          <Button
            icon="copy"
            className="btn-action-add"
            title="Copy Link"
            style={{ marginLeft: '10px' }}
            onClick={() => copySharedLinkToClipboard(url)}
          />
        </p>
      ) : (
        <></>
      )}
    </Dialog>
  );
};

export default SharedLinkCreator;
